import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Proctoring from '@/components/Proctoring'

export default [
  {
    path: '/enter/:token',
    components: {
      header: Navbar,
      default: () => import('@/views/Home/Index')
    },
  },
  {
    name: 'identification',
    path: '/identification',
    components: {
      header: Navbar,
      default: () => import('@/views/Home/Identification')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'about',
    path: '/about',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/About')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'about-critical',
    path: '/about-critical',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/AboutCritical')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'about-new',
    path: '/about-new/:type',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/AboutNew')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'collaboration',
    path: '/collaboration',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/Collaboration')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'question',
    path: '/question/:id',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/Index')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'critical thinking',
    path: '/critical-thinking',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/CriticalThinking')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'creativity',
    path: '/creativity',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/Сreativity')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'communication',
    path: '/communication',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/Сommunications')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'self-esteem',
    path: '/self-esteem',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/SelfEsteem')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'completed',
    path: '/completed',
    components: {
      header: Navbar,
      default: () => import('@/views/Completed')
    },
  },
  {
    name: 'results',
    path: '/results',
    components: {
      header: Navbar,
      default: () => import('@/views/Results')
    },
  },
  {
    name: 'question-preview',
    path: '/question-preview/:id',
    components: {
      default: () => import('@/views/PreviewQuestion')
    }
  },
  {
    name: 'not-found',
    path: '*',
    components: {
      header: Navbar,
      default: Empty
    }
  }
]
